/*
 * > This component routes all requests to the app to the relevent component
 * Developed by: Anele Siwawa
 * Date : 20.06.19
 * About this page: 
 * >  This page uses lazy and Supense to help give user feedback when the network is slow
 * > firebase is used as our database, hosting
 * > Browserrouter, switch and route help in routing the correct component from library react-router-dom 
 * > RiseLoader is the loader animation chosen to show user when changing betwen components 
 *   taken from react-spinners library 
 * > css from emotion core helps style the loader
 * > TopbarProgress is used when the page is loading
 * 
 */


 import React, { Component,lazy, Suspense } from 'react';
import './App.css';
import firebase from 'firebase';
import logo from '../../logo.svg';
import { BrowserRouter, Switch , Route} from 'react-router-dom';
import { RiseLoader } from 'react-spinners';
import { css } from '@emotion/core';
import TopBarProgress from "react-topbar-progress-indicator";




// Importing the components
const Welcome = lazy(() => import("../Welcome"));
const Farms = lazy(() => import("../Farms"));
const Farm = lazy(() => import("../Farm"));
const Investment = lazy(() => import("../Investment"));
const PersonWelcome = lazy(() => import("../Person/PersonWelcome"));
const Home = lazy(() => import("../Person/Home"));
const FarmerHome = lazy(() => import("../farmer/FarmerHome"));
const FarmerProf = lazy(() => import("../farmer/FarmerProf"));
const FarmerProject = lazy(() => import("../farmer/FarmerProject"));
const FarmerWelcome = lazy(() => import("../farmer/FarmerWelcome"));
const Update = lazy(() => import("../farmer/Update"));
const PasswordReset = lazy(() => import("../PasswordReset"));

// styling the loader
const override = css`
    display: block;
    margin: 10 auto;
    text-align: center;
    border-color: #AAE694;
    vertical-align: middle;
    horizontal-align: middle;
`;

// Top bar styling
TopBarProgress.config({
  barColors: {
    "0": "#3D8C30",
    "1.0": "#AAE694",
  },
  shadowBlur: 5,
})



// Class starts here
class App extends Component {
  // initialising state
  constructor(props){
    super(props);

     // Initialising firebase
    var firebaseConfig = {
      apiKey: "AIzaSyCL7RmsXi3cHu4x3-N16DZ2rCI2duDORds",
      authDomain: "newfirebaseitem.firebaseapp.com",
      databaseURL: "https://newfirebaseitem.firebaseio.com",
      projectId: "newfirebaseitem",
      storageBucket: "newfirebaseitem.appspot.com",
      messagingSenderId: "533889056706",
      appId: "1:533889056706:web:09f4922861f0482d"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    this.state = {
        loading: false,
    };
  }





  render() {
    const { loading } = this.state;


    let loadingView = <div className="App">
      <TopBarProgress />
      <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
      </div>;


      let mainView = 
      <BrowserRouter>
       <Switch>
        <Route exact path="/" render={props => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#3D8C30'}
          loading={true}
        />
      </div> }>   <Welcome db={firebase} /></Suspense>} />
      <Route exact path="/farms" render={props => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#3D8C30'}
          loading={true}
        />
      </div> }>   <Farms db={firebase} /></Suspense>} />
      <Route exact path="/farm/:id" render={({match}) => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#3D8C30'}
          loading={true}
        />
      </div> }> <Farm db={firebase} farm={match} /></Suspense>} />
      <Route exact path="/investment" render={() => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#3D8C30'}
          loading={true}
        />
      </div> }> <Investment db={firebase} /></Suspense>} />
        <Route exact path="/home" render={() => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#3D8C30'}
          loading={true}
        />
      </div> }><Home db={firebase}/> </Suspense>} />
        <Route exact path="/profile" render={() => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#3D8C30'}
          loading={true}
        />
      </div> }><Home db={firebase} /> </Suspense>} />
      <Route exact path="/explore" render={() => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#3D8C30'}
          loading={true}
        />
      </div> }><Home db={firebase} /> </Suspense>} />
      <Route exact path="/search/:search" render={({match}) => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#3D8C30'}
          loading={true}
        />
      </div> }><Home term={match} db={firebase} /> </Suspense>} />
      <Route exact path="/welcome" render={() => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#FFF'}
          loading={true}
        />
      </div> }><PersonWelcome db={firebase} /> </Suspense>} />
      <Route exact path="/passwordreset" render={() => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#FFF'}
          loading={true}
        />
      </div> }><PasswordReset db={firebase} /> </Suspense>} />
      <Route isExact={false} path="/_/auth/:info" render={({match}) => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#FFF'}
          loading={true}
        />
      </div> }><PasswordReset data={match} db={firebase} /> </Suspense>} />
      <Route exact path="/farmerwelcome" render={() => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#FFF'}
          loading={true}
        />
      </div> }><FarmerWelcome db={firebase} /> </Suspense>} />
        <Route exact path="/farmer" render={() => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#3D8C30'}
          loading={true}
        />
      </div> }><FarmerProf db={firebase} /> </Suspense>} />
        <Route exact path="/farmeradmin" render={() => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#3D8C30'}
          loading={true}
        />
      </div> }><FarmerWelcome db={firebase} /> </Suspense>} />  
      <Route exact path="/project/:projectId" render={({match}) => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#3D8C30'}
          loading={true}
        />
      </div> }><Home project={match} db={firebase} /> </Suspense>} />
      <Route exact path="/thread/:projectId" render={({match}) => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#3D8C30'}
          loading={true}
        />
      </div> }><Home project={match} db={firebase} /> </Suspense>} />
      <Route exact path="/post/:postId" render={({match}) => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#3D8C30'}
          loading={true}
        />
      </div> }><FarmerProject post={match} db={firebase} /> </Suspense>} />
      <Route exact path="/farmerupdate/:farmerId" render={({match}) => <Suspense fallback={<div className='App-loader'>
        <RiseLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={'#3D8C30'}
          loading={true}
        />
      </div> }><Update farmer={match} db={firebase} /> </Suspense>} />
      <Route exact path='/farmpartner' render={({match}) =>  <Suspense fallback={<div className='App-loader'>
          <RiseLoader
            css={override}
            sizeUnit={"px"}
            size={10}
            color={'#3D8C30'}
            loading={true}
          />
        </div> }><Home db={firebase} /> </Suspense>} />
      <Route exact path='/farmpartner/:id' render={({match}) =>  <Suspense fallback={<div className='App-loader'>
          <RiseLoader
            css={override}
            sizeUnit={"px"}
            size={10}
            color={'#3D8C30'}
            loading={true}
          />
        </div> }><Home db={firebase} /> </Suspense>} />
         <Route exact path='/farmer/:id' render={({match}) =>  <Suspense fallback={<div className='App-loader'>
          <RiseLoader
            css={override}
            sizeUnit={"px"}
            size={10}
            color={'#3D8C30'}
            loading={true}
          />
        </div> }><Home farmer={match} db={firebase} /> </Suspense>} />
        <Route exact path='/payment/:id' render={({match}) =>  <Suspense fallback={<div className='App-loader'>
          <RiseLoader
            css={override}
            sizeUnit={"px"}
            size={10}
            color={'#3D8C30'}
            loading={true}
          />
        </div> }><Home project={match} db={firebase} /> </Suspense>} />
        <Route exact path='/return' render={({match}) =>  <Suspense fallback={<div className='App-loader'>
          <RiseLoader
            css={override}
            sizeUnit={"px"}
            size={10}
            color={'#3D8C30'}
            loading={true}
          />
        </div> }><Home project={match} db={firebase} /> </Suspense>} />
        </Switch>
      </BrowserRouter>;
   
    return (
      loading ? <div> {loadingView} </div> : <div>{mainView}</div>
    );
  }
}

export default App;
